import { Link } from "react-router-dom";
import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import Swal from "sweetalert2";
import { urlAPI } from "../config/global";
import axios from "axios";
import { ConstructionRounded } from "@mui/icons-material";

const loggedInNavItems = [
  { name: "Kehadiran", href: "/kehadiran" },
  { name: "Absen", href: "/presensi" },
  { name: "Shift", href: "/shift" },
  { name: "Jadwal", href: "/jadwal-kehadiran" },
  { name: "Data Pegawai", href: "/data-pegawai" },
];

const notLoggedInNavItems = [
  { name: "Kehadiran", href: "/kehadiran" },
  { name: "Absen", href: "/presensi" },
  { name: "Izin", href: "", izin: true },
];

const masterData = [
  { name: "Rekap Kehadiran Dokter", href: "/rekap-kehadiran-dokter" },
  { name: "Rekap Kehadiran Dokter Gigi", href: "/rekap-kehadiran-dokter-gigi" },
  { name: "Rekap Kehadiran Perawat", href: "/rekap-kehadiran-perawat" },
  {
    name: "Rekap Kehadiran Perawat Gigi",
    href: "/rekap-kehadiran-perawat-gigi",
  },
  { name: "Rekap Kehadiran Farmasi", href: "/rekap-kehadiran-farmasi" },
  { name: "Rekap Kehadiran Apoteker", href: "/rekap-kehadiran-apoteker" },
  { name: "Rekap Kehadiran Analis", href: "/rekap-kehadiran-analis" },
  { name: "Rekap Kehadiran CS", href: "/rekap-kehadiran-cs" },
  {
    name: "Rekap Kehadiran Pegawai Kantor",
    href: "/rekap-kehadiran-pegawai-kantor",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navigation = (props) => {
  const isLoggedIn = sessionStorage.getItem("user");
  const [isIzin, setIsizin] = useState(false);
  const handleLogout = () => {
    Swal.fire({
      title: "Yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonText: "Keluar",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("user");
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Berhasil logout",
          showConfirmButton: false,
          timer: 1500,
          didClose: () => {
            window.location.href = "/";
          },
        });
      }
    });
  };

  const getData = async () => {
    const arg = { bulan: "Agustus", tahun: "2024" };

    try {
      const response = await axios.post(
        `${urlAPI}/rekap-kehadiran-dokter/get-insentif`,
        arg,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Proses untuk menghitung total dan menambahkan properti baru
      const sales = response.data;
      const data = sales.forEach((sale) => {
        const totalGrossAmount = sale.salesdetail.reduce(
          (total, detail) => total + detail.grossamount,
          0
        );
        const totalNetAmount = sale.salesdetail.reduce(
          (total, detail) => total + detail.netamount,
          0
        );
        const totalPrice = sale.salesdetail.reduce(
          (total, detail) => total + detail.price,
          0
        );
        const cost = sale.salesdetail.reduce(
          (total, detail) => total + detail.price - detail.costprice,
          0
        );
        // Menambahkan total ke dalam properti baru pada objek sales
        sale.totalGrossAmount = totalGrossAmount;
        sale.totalNetAmount = totalNetAmount;
        sale.totalPrice = totalPrice;
        sale.totalCost = cost;
        sale.totalDetail = sale.salesdetail.length;
      });

      const dataFilter = sales.map((a) => {
        return {
          gross: a.totalGrossAmount,
          net: a.totalNetAmount,
          price: a.totalPrice,
          cost: a.totalCost,
          total: a.totalDetail,
          man: a.salesmanid,
        };
      });
      const totalGrossAmount = dataFilter.reduce(
        (total, detail) => total + detail.gross,
        0
      );
      const totalNetAmount = dataFilter.reduce(
        (total, detail) => total + detail.net,
        0
      );

      console.log(sales);
      const total = dataFilter.reduce((a, detail) => a + detail.total, 0);
      // Menggabungkan semua objek dalam detailSales menjadi satu array
      // Menggabungkan semua objek dalam detailSales menjadi satu array
      const allDetailSales = sales.flatMap((sale) => sale.salesdetail);

      const cleanedData = allDetailSales.map((item) => ({
        ...item,
        formula: item.formula.replace("v.ProductQuantity*", ""), // Menghapus kata "Tahun"
      }));
      // Mengelompokkan berdasarkan productid dan menghitung jumlahnya
      // Mengelompokkan berdasarkan productid dan menghitung jumlahnya serta menjumlahkan nilai lainnya
      const groupedByProductId = cleanedData.reduce((acc, item) => {
        // Jika productid belum ada di accumulator, inisialisasi
        if (!acc[item.productid]) {
          acc[item.productid] = {
            productid: item.productid,
            nama: item.name,
            count: 0,
            cost: item.costprice,
            price: item.price,
            totalCost: 0,
            totalPrice: 0,
            totalLaba: 0,
            totalKomisi: 0,
          };
        }

        // Menambahkan jumlah kemunculan
        acc[item.productid].count += 1;

        // Menambahkan total grossamount, netamount, dan price
        acc[item.productid].totalCost += item.costprice * item.salesqty;
        acc[item.productid].totalPrice += item.price * item.salesqty;
        acc[item.productid].totalLaba += Math.abs(
          item.price * item.salesqty - item.costprice * item.salesqty
        );
        acc[item.productid].totalKomisi +=
          parseInt(item.formula) * item.salesqty;
        return acc;
      }, {});

      // Mengonversi hasil menjadi array
      const result = Object.values(groupedByProductId);
      const sortedResult = result.sort((a, b) =>
        a.productid.localeCompare(b.productid)
      );
      const totalPrice = dataFilter.reduce(
        (total, detail) => total + detail.net,
        0
      );
      const totalCost = result.reduce(
        (total, detail) => total + Math.round(detail.totalLaba, 4),
        0
      );
      const komisi = result.reduce(
        (total, detail) => total + Math.round(detail.totalKomisi, 4),
        0
      );
      console.log(sortedResult);
      console.log("total gross", totalGrossAmount);
      console.log("total net", totalNetAmount);
      console.log("total Price", totalPrice);
      console.log("total Cost", totalCost);
      console.log("total Commision", komisi);
      console.log(sales);
      console.log(allDetailSales);
    } catch (error) {
      console.log("Error pada tanggal:", error);
    }
  };
  const handleIzin = () => {
    props.openIzin();
  };
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center">
                {/* Mobile menu button*/}
                {/* <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button> */}
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                </div>
                <div className=" sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {isLoggedIn ? (
                      <>
                        {loggedInNavItems.map((item) => (
                          <Link
                            to={item.href}
                            className={classNames(
                              "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                          >
                            {item.name}
                          </Link>
                        ))}
                        <Menu key={"Master Data"}>
                          <Menu.Button
                            as={Link}
                            to="#"
                            className={classNames(
                              "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                          >
                            Master Data
                          </Menu.Button>

                          <Menu.Items className="origin-top-left absolute mt-2 left-[30%] w-50 rounded-md shadow-lg bg-white top-[75%] z-[9999] ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1" style={{ zIndex: "999" }}>
                              {masterData.map((data, index) => (
                                <Menu.Item key={index}>
                                  {({ active }) => (
                                    <Link
                                      to={data.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {data.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Menu>
                      </>
                    ) : (
                      notLoggedInNavItems.map((item) => (
                        <>
                          {item.izin ? (
                            <>
                              <button
                                className={classNames(
                                  "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "rounded-md px-3 py-2 text-sm font-medium"
                                )}
                                onClick={handleIzin}
                              >
                                Izin
                              </button>
                            </>
                          ) : (
                            <>
                              <Link
                                key={item.name}
                                to={item.href}
                                className={classNames(
                                  "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "rounded-md px-3 py-2 text-sm font-medium"
                                )}
                              >
                                {item.name}
                              </Link>
                            </>
                          )}
                        </>
                      ))
                    )}
                    {/* <button
                      className="flex justify-center items-center p-2 px-6 bg-white font-medium"
                      onClick={getData}
                    >
                      Cek Insentif
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Tombol Login */}
                {isLoggedIn ? (
                  <button
                    onClick={handleLogout}
                    className={classNames(
                      "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "rounded-md px-3 py-2 text-sm font-medium"
                    )}
                  >
                    Logout
                  </button>
                ) : (
                  <Link
                    to="/login"
                    className={classNames(
                      "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "rounded-md px-3 py-2 text-sm font-medium"
                    )}
                  >
                    Login Admin
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;
