// const protocol = window.location.protocol;
// const host = "202.157.189.177:5000";

// export const urlAPI = `http://localhost:5000`;

// export const urlAPI = "http://localhost:5000";
export const urlAPI = `https://apipresensi.klinikkosasih.com`;
export const botTokenTelegram =
  "6450512353:AAEPzql6mikTouuEse_S_GhzA7XlmC1lNgE";
export const chatIdTelegram = "1391434253";
